.swal2-shown {
    overflow-x: unset;

    .swal2-popup {
        padding: 0;
        border-radius: $modal-content-border-radius;

        .swal2-checkbox,
        .swal2-file,
        .swal2-input,
        .swal2-radio,
        .swal2-select,
        .swal2-textarea {
            margin: auto;
        }

        .swal2-header {
            align-items: start;
            padding: 20px;

            .swal2-progresssteps {
                margin: 0;
                padding: 10px 0 20px;
            }

            .swal2-icon {
                order: 2;
                margin: 30px auto 0;
            }

            .swal2-image {
                order: 3;
                margin: 30px auto 0;
            }

            .swal2-title {
                font-size: 1.538rem;
                font-weight: $font-weight-light;
                order: 1;
                margin: 0;
                line-height: $line-height-base;
            }
        }

        .swal2-close {
            width: auto;
            height: auto;
            right: 5px;
            outline: none;
            opacity: 0.4;
            color: $black;
            font-size: 2.15rem;
            font-weight: bold;
            line-height: 1;
            padding: 1rem;

            &:hover {
                opacity: 0.75;
            }
        }

        .swal2-content {
            padding: 10px 20px 20px;

            #swal2-content {
                text-align: left;
                font-size: 1rem;
                color: $body-color;
                font-weight: $font-weight-normal;
                line-height: 21px;
            }

            .swal2-textarea {
                min-height: 100px;
            }

            .swal2-input, .swal2-textarea, .swal2-select {
                display: block;
                width: 100%;
                height: $input-height;
                padding: $input-padding-y $input-padding-x;
                font-size: $font-size-base;
                line-height: $input-line-height;
                color: $input-color;
                background-color: $input-bg;
                background-clip: padding-box;
                border: $input-border-width solid $input-border-color;

                border-width: 1px;
                border-top-color: $input-top-border;
                box-shadow: none;
                padding: 10px 12px;
                font-size: $font-size-base + 0.1538rem;
                transition: none;
                height: $input-height;
                
                @if $enable-rounded {
                    border-radius: $input-border-radius;
                } @else {
                    border-radius: 0;
                }
                
                @include box-shadow($input-box-shadow);
                @include transition($input-transition);
                
                &::-ms-expand {
                    background-color: transparent;
                    border: 0;
                }
                
                @include form-control-focus();
                
                &::placeholder {
                    color: $input-placeholder-color;
                    opacity: 1;
                }

                &:disabled,
                &[readonly] {
                    background-color: $input-disabled-bg;
                    opacity: 1;
                }
            }
        }

        .swal2-actions {
            padding: 20px;
            margin: 0;
            justify-content: end;
            
            .swal2-styled {
                display: inline-block;
                font-weight: $btn-font-weight;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                border: $btn-border-width solid transparent;
                @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
                @include transition($btn-transition);

                box-shadow: $btn-box-shadow-base;
                border: 1px solid transparent;
                margin: 0;
                margin-left: 8px;
                @include button-size($btn-padding-vertical, $btn-padding-horizontal, $btn-font-size-base, $btn-line-height-base, $btn-border-radius-base);

                &:active,
                &.active {
                    box-shadow: none;
                }

                &:active:focus {
                    outline: none;
                }

                // Share hover and focus styles
                @include hover-focus {
                    text-decoration: none;
                }

                &:focus,
                &.focus {
                    outline: 0;
                    box-shadow: $btn-focus-box-shadow;
                }

                // Disabled comes first so active can properly restyle
                &.disabled,
                &:disabled {
                    opacity: $btn-disabled-opacity;
                    @include box-shadow(none);
                }

                // Opinionated: add "hand" cursor to non-disabled .btn elements
                &:not(:disabled):not(.disabled) {
                    cursor: pointer;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled).active {
                    @include box-shadow($btn-active-box-shadow);

                    &:focus {
                    @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
                    }
                }

                &.swal2-confirm {
                    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
                }

                &.swal2-cancel {
                    color: $btn-secondary-color;
                    background-color: $btn-secondary-bg;
                    border-color: $btn-secondary-border;

                    &:focus,
                    &.focus {
                        color: $btn-secondary-color;
                        background-color: $btn-secondary-bg;
                        border-color: $btn-secondary-border;
                        box-shadow: $btn-box-shadow-base;
                    }

                    &:hover, &.hover{
                        color: $btn-secondary-color;
                        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                        background-color: $btn-secondary-bg;
                        border-color: darken($btn-secondary-border, 13%);
                    }
                        
                    &:active,
                    &.active,
                    &:not([disabled]):not(.disabled):active,
                    &:not([disabled]):not(.disabled).active,
                    .show > &.dropdown-toggle {
                        box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
                        color: darken($btn-secondary-color, 10%);
                        background-color: darken($btn-secondary-bg, 11%);
                        border-color: darken($btn-secondary-border, 13%);

                        &:hover,
                        &:focus,
                        &.focus {
                            color: darken($btn-secondary-color, 10%);
                            background-color: darken($btn-secondary-bg, 11%);
                            border-color: darken($btn-secondary-border, 13%);
                            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
                        }
                    }

                    &:active,
                    &.active,
                    .show > &.dropdown-toggle {
                        background-image: none;
                    }

                    &.disabled,
                    &[disabled],
                    fieldset[disabled] & {
                        color: $btn-secondary-color;

                        &,
                        &:hover,
                        &:focus,
                        &.focus,
                        &:active,
                        &.active {
                            color: $btn-secondary-color;
                            background-color: $btn-secondary-bg;
                            border-color: $btn-secondary-border;
                            box-shadow: none;

                            .icon {
                                color: $btn-secondary-color;
                            }
                        }
                    }

                    //icon color
                    .icon {
                        color: lighten($btn-secondary-color, 14%);
                    }

                    &:active {

                        .icon {
                            color: lighten($btn-secondary-color, 4%);
                        }
                    }

                    .badge {
                        color: $btn-secondary-bg;
                        background-color: $btn-secondary-color;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &.swal2-loading {

            .swal2-actions {

                .swal2-styled {

                    &.swal2-confirm {
                        border-color: transparent;
                    }
                }
            }
        }

        .swal2-footer {
            margin: 0;
            padding: 20px;
            justify-content: left;
        }
    }

    .content-text-center {

        &.swal2-popup {
            
            .swal2-content {
        
                #swal2-content {
                    text-align: center;          
                }
            }
        }
    }

    .content {
    
        &-header {

            &-left {

                &.swal2-popup {
                    
                    .swal2-header {
                        align-items: start;
                    }
                }
            }

            &-center {

                &.swal2-popup {
                    
                    .swal2-header {
                        align-items: center;
                    }
                }
            }

            &-right {

                &.swal2-popup {
                    
                    .swal2-header {
                        align-items: end;
                    }
                }
            }

            &-title {

                &-left {

                    &.swal2-popup {
                        
                        .swal2-header {

                            .swal2-title {
                                align-self: start;
                            }
                        }
                    }
                }
    
                &-center {
    
                    &.swal2-popup {
                        
                        .swal2-header {

                            .swal2-title {
                                align-self: center;
                            }
                        }
                    }
                }
    
                &-right {
    
                    &.swal2-popup {
                        
                        .swal2-header {

                            .swal2-title {
                                align-self: end;
                            }
                        }
                    }
                }
            }
        }

        &-text {

            &-left {

                &.swal2-popup {
                    
                    .swal2-content {
        
                        #swal2-content {
                            text-align: left;          
                        }
                    }
                }
            }

            &-center {

                &.swal2-popup {
                    
                    .swal2-content {
        
                        #swal2-content {
                            text-align: center;          
                        }
                    }
                }
            }

            &-right {

                &.swal2-popup {
                    
                    .swal2-content {
        
                        #swal2-content {
                            text-align: right;          
                        }
                    }
                }
            }
        }

        &-actions {

            &-left {

                &.swal2-popup {
                    
                    .swal2-actions {
                        justify-content: start;
                    }
                }
            }

            &-center {

                &.swal2-popup {
                    
                    .swal2-actions {
                        justify-content: center;
                    }
                }
            }

            &-right {

                &.swal2-popup {
                    
                    .swal2-actions {
                        justify-content: end;
                    }
                }
            }
        }

        &-footer {

            &-left {

                &.swal2-popup {
                    
                    .swal2-footer {
                        justify-content: start;
                    }
                }
            }

            &-center {

                &.swal2-popup {
                    
                    .swal2-footer {
                        justify-content: center;
                    }
                }
            }

            &-right {

                &.swal2-popup {
                    
                    .swal2-footer {
                        justify-content: end;
                    }
                }
            }
        }
    }

    .colored-header {

        &.swal2-popup {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            
            .swal2-header {
                padding: 30px 20px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;

                .swal2-title, .swal2-icon {
                    color: theme-color("light");
                    border-color: theme-color("light");
                }
            }

            .swal2-content {
                padding: 35px 20px 20px;
            }

            .swal2-actions {

                .swal2-styled {

                    &.swal2-confirm {
                        border-left-color: transparent !important;
                        border-right-color: transparent !important;
                    }
                }
            }
        }

        .swal2-close {
            color: rgba(0, 0, 0, 0.7);
            line-height: 1.95;
        }

        &-success {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("success");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
                        }
                    }
                }
            }
        }

        &-primary {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("primary");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
                        }
                    }
                }
            }
        }

        &-info {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("info");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
                        }
                    }
                }
            }
        }

        &-warning {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("warning");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
                        }
                    }
                }
            }
        }

        &-danger {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("danger");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
                        }
                    }
                }
            }
        }

        &-dark {

            &.swal2-popup {

                .swal2-header {
                    background-color: theme-color("dark");
                }

                .swal2-actions {
    
                    .swal2-styled {

                        &.swal2-confirm {
                            @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
                        }
                    }
                }
            }
        }
    }

    .modal-full-color {

        &.swal2-popup {
            
            .swal2-header {

                .swal2-title, .swal2-icon {
                    color: theme-color("light");
                    border-color: theme-color("light");
                }
            }

            .swal2-content {

                #swal2-content {
                    color: theme-color("light");
                }
            }

            .swal2-actions {

                .swal2-styled {
                    border-width: 2px;

                    &.swal2-confirm {
                        border-color: transparent;
                        border-left-color: transparent !important;
                        border-right-color: transparent !important;
                        background-color: theme-color("light");

                        &:hover {
                            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                            background-color: theme-color("light");
                            border-color: transparent;
                            border-bottom-color: transparent;
                            background-image: unset;
                        }

                        &:active {
                            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06) !important;
                            color: darken($btn-secondary-color, 10%) !important;
                            background-color: darken($btn-secondary-bg, 11%) !important;
                            border-color: darken($btn-secondary-border, 13%) !important;
                        }
    
                        &:focus {
                            border-color: transparent;
                            background-color: theme-color("light");
                        }
                    }

                    &.swal2-cancel {
                        color: theme-color("light");
                        border-color: rgba(255, 255, 255, 0.5);
                        background-color: transparent;

                        &:hover, &:active {
                            color: theme-color("light") !important;
                            box-shadow: none !important;
                            background-color: rgba(255, 255, 255, 0.1) !important;
                            border-color: rgba(255, 255, 255, 0.8) !important;
                            background-image: unset !important;
                        }

                        &:focus {
                            color: theme-color("light");
                            background-color: transparent;
                            border-color: rgba(255, 255, 255, 0.4);
                        }
                    }
                }
            }
        }

        .swal2-close {
            color: theme-color("light");
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }

        &-success {
            background-color: theme-color("success");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("success");

                        &:hover {
                            color: theme-color("success");
                        }

                        &:focus {
                            color: theme-color("success");
                        }
                    }
                }
            }
        }

        &-primary {
            background-color: theme-color("primary");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("primary");

                        &:hover {
                            color: theme-color("primary");
                        }

                        &:focus {
                            color: theme-color("primary");
                        }
                    }
                }
            }
        }

        &-info {
            background-color: theme-color("info");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("info");

                        &:hover {
                            color: theme-color("info");
                        }

                        &:focus {
                            color: theme-color("info");
                        }
                    }
                }
            }
        }

        &-warning {
            background-color: theme-color("warning");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("warning");

                        &:hover {
                            color: theme-color("warning");
                        }

                        &:focus {
                            color: theme-color("warning");
                        }
                    }
                }
            }
        }

        &-danger {
            background-color: theme-color("danger");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("danger");

                        &:hover {
                            color: theme-color("danger");
                        }

                        &:focus {
                            color: theme-color("danger");
                        }
                    }
                }
            }
        }

        &-dark {
            background-color: theme-color("dark");

            &.swal2-popup {
    
                .swal2-actions {
    
                    .swal2-styled {
                        color: theme-color("dark");

                        &:hover {
                            color: theme-color("dark");
                        }

                        &:focus {
                            color: theme-color("dark");
                        }
                    }
                }
            }
        }
    }
}

